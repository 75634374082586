.bg1 {
    background: url(https://i.ibb.co/MNkCPrH/bg1.png) repeat; 
    background-size: cover;
} 

.bg2 {
    background: url(https://i.ibb.co/nzRK9XC/bg2.png) no-repeat;
    background-position: center;
    background-size: cover;
}

.min__height__bg {
    min-height: 85vh;
}

.bg3{
    background-color: #1C304F !important;
}

.mapa__big__card {
    background-color: white;
    border-radius: 6px;
    border-top: 5px solid #2A5DAC;
}

.mapa__phase {
    margin: 0px 20px 0px 0px;
}

.mapa__tile {
    margin: 0px 20px 15px 0px;
}

.mapa__desc {
    margin: 0px 20px 0px 0px;
}

.mapa__big__card__2 {
    margin-top: 120px;
    min-height: 370px;
    background-color: white;
    border-bottom: 5px solid #2A5DAC;
}

.mapa__big__card__2__title {
    line-height: 30px;
    margin: 20px 0px 20px 0px;
}

.bonus__image {
    margin-top: -120px;
}



.btn__cta {
    padding-top: 17px;
    padding-bottom: 17px;
    border: transparent;
}

.arrow__left {
    margin-left: 15px;
    margin-right: -15px;
}

.video__adjust {
    border-radius: 6px
}






.price__card {
    border-radius: 6px;
    padding: 35px;
    background-color: white;
}

.price__margin {
    margin: 20px 20px 30px 20px;
}

.card__warranty {
    background-color: white;
    border-left: 5px solid #2A5DAC;
    border-right: 5px solid #2A5DAC;
    
    padding: 35px 50px 35px !important;
}
.warraty__text {
    margin-top: 45px;
}






.accordion-button::after {
    background-image: url(../../assets/arrowD.svg) !important;
}

@media(max-width: 1200px) {
    .font36 {
        text-align: center;
        font-size: 24px;
    }
    .font24 {
        font-size: 18px;
    }
    .font18 {
        font-size: 16px;
    }
    .mapa__margin__adjust {
        text-align: center;
        padding: 0 !important;
        margin: 20px 0px 0px !important;
    }

    .mapa__phase {
        text-align: center;
        margin: 0px 20px 0px 20px;
    }
    
    .mapa__tile {
        text-align: center;
        margin: 0px 20px 15px 20px;
    }
    
    .mapa__desc {
        text-align: center;
        margin: 0px 20px 30px 20px;
    }
    .mapa__big__icon {
        padding: 25px 0px 25px 0px !important;
    }
    .mapa__text__centered {
        text-align: center;
    }
    .mapa__big__card__2 {
        min-height: 200px;
    }
    .video__adjust {
        padding: 0 !important;
        width: 100%;
        margin-top: 25px;
    }
    .price__margin {
        margin: 20px 0px 20px !important;
        width: 250px;
    }
    .price__card {
        border-radius: 6px;
        padding: 5px 20px 20px;
        background-color: white;
    }
    .btn__cta {
        font-size: 14px !important;
    }
    .text__adjust__mob {
        text-align: center;
    }

    .adjust__mob__100 {
        width: 100%;
    }
    .logo {
        height: 55px;
    }
    .image__expert__4 {
        margin: 10px 0px 20px;
    }
    .image__expert__4__mob {
        margin: 0px 0px 0px;
        padding: 0px 90px 0px !important;        
    }
}